<template>
    <div id="about" class="container">
        <h1>About Me</h1>
        <p>
            Recently a mother. <br>
            Still (somehow) a wife. <br>
            No longer a victim. <br>
            Figuring out the rest. <br>
        </p>
        <p>I started this journal six weeks after I had a mental breakdown. I had begun being brutally honest with myself, and soon discovered that honesty is the ultimate form of self-love, and the fastest route to happiness. My mindset shifted and life suddenly got good - really, really good. It got so good that I started panicking it wouldn’t be good forever, so I wrote it all down. Then I decided to publish it because Brené Brown told me that vulnerability is everything, and I thought that publicly highlighting my (former, FORMER) use of illegal substances would surely make my mum and dad proud. Now, there are days where life is really good, I feel like I have it all figured out, and days where I wonder what the hell I am doing. Welcome.</p>
        <p>Email Zoe: <a href="mailto:morning.walks1@gmail.com">morning.walks1@gmail.com</a></p>
    </div>
</template>

<script>
export default {
    
}
</script>